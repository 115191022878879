import { useState, useEffect, useRef } from 'react';
import { 
	Flex,
	Image,
	Text,
  	ChakraProvider,
	Spinner,
} from "@chakra-ui/react";
import "./App.scss";
import "./mdi/css/materialdesignicons.css";
import Aos from "aos";
import "aos/dist/aos.css";
import cover1 from "./cover1.jpg"
import cover2 from "./cover2.jpg"
import cover3 from "./cover3.jpg"
import cover4 from "./cover4.jpg"
import logo from "./logo.png"
import about from "./about.jpg"
import { Accordion, AccordionItem as NewItem } from '@szhsin/react-accordion'

function App() {
	useEffect(() => {
		Aos.init({ duration: 1000 })
	}, [])


	const AccordionItem = ({ header, ...rest }) => (
        <NewItem
          {...rest}
          header={
            <Flex align="center" w="100%" mb={["-2", "0"]}>
              <Text className="font" fontSize={["16px", "18px"]} fontWeight="400">{header}</Text>
              <i className='mdi mdi-chevron-down chevron-down' style={{ fontSize: '25px' }}></i>
            </Flex>
          }
        />
    );


	
    useEffect(() => {
        const scEleTwo = document.getElementById('scEleTwos')
        const scEleTwoWidth = scEleTwo.scrollWidth
        setInterval(() => {
            if (scEleTwo.scrollLeft < scEleTwoWidth) {
                scEleTwo.scrollTo(scEleTwo.scrollLeft + 1, 0)  
            }
        }, 15)
    }, [])


	return (
		<ChakraProvider>
			<Flex w="100%" fontSize="15px" direction="column" color="#000">
				<Flex zIndex="10" data-aos="fade-down" backdropFilter="blur(8px)" w={["41px", "56px"]} h={["40px", "55px"]} position="fixed" top="0" right="0" justify="center" align="center" mt={["5", "6"]} mr={["5", "8%"]} bg="rgba(100,100,100,0.5)" borderRadius="100%" color="#fff" fontSize={["20px", "30px"]} cursor="pointer" onClick={() => {
					window.tidioChatApi.open()
				}}><i className="mdi mdi-account-outline"></i></Flex>

				
				<Flex zIndex="10" data-aos="fade-down" w={["41px", "56px"]} h={["40px", "55px"]} position="absolute" top="0" left="0" justify="center" align="center" mt={["5", "6"]} ml={["5", "8%"]} borderRadius="100%" color="#fff" fontSize="30px" cursor="pointer" onClick={() => window.scrollTo(0,0)}><Image src={logo} w={["35px", "50px"]} /></Flex>


				<Flex w="100%" minH={["100%", "100vh"]} direction={["column", "row"]} bg="#000" align="center" pt={["120px", "12"]} pb={["12", "6"]} px={["5", "8%"]} color="#fff">
					<Flex w={["100%", "48%"]} direction="column" pr={["0", "6%"]}>
						<Text fontWeight="700" fontSize={["26px", "32px"]} data-aos="fade-down">Middlesales International <Text as="span" fontWeight="300">(MSI)</Text></Text>
						<Text fontWeight="700" fontSize={["17px", "20px"]} mt="2">Connect Buyers and Sellers with Confidence with Middlesales International</Text>
						<Text color="rgb(150,150,150)" mt="5" fontSize={["13px", "15px"]} data-aos="fade-up">Welcome to Middlesales International, the e-commerce platform that connects buyers and sellers across the world. Unlike traditional P2P marketplaces, we provide a transparent and secure environment for transactions, with local agents in every country to ensure complete transparency and trust.</Text>
						<Flex data-aos="fade-up" align="center" mt="8"><Text mr="3" color="#e0960d">➜</Text><Flex bg="#e0960d" color="#fff" cursor="pointer" borderRadius="30px" p="12px 50px" fontWeight="700" onClick={() => {
							window.tidioChatApi.open()
						}}>Begin</Flex><Flex ml={["3", "4"]} bg="#e0960d" color="#fff" cursor="pointer" borderRadius="30px" p="12px 50px" fontWeight="700" onClick={() => {
							const element = document.getElementById('aCrea')
							return window.scrollTo({
								top: element ? element.offsetTop - 50 : 0,
								behavior: 'smooth',
							})
						}}>Offers</Flex></Flex>
					</Flex>
					<Flex w={["100%", "52%"]} mt={["12", "0"]} minH={["40vh", "70vh"]} data-aos="fade-left" backgroundImage={cover1} backgroundRepeat="no-repeat" backgroundPosition="center left" backgroundSize="cover" borderRadius="8px">
					</Flex>
				</Flex>


				<Flex w="100%" py={["60px", "80px"]} bg="#fff" px={["5", "8%"]} align="center" direction={["column", "row"]}>
					<Flex display={["none", "flex"]} w={["100%", "50%"]} backgroundImage={about} backgroundRepeat="no-repeat" backgroundPosition="center center" backgroundSize="cover" borderRadius="8px" h={["80px", "700px"]}></Flex>
					<Flex w={["100%", "45%"]} ml={["0", "5%"]} direction="column">
						<Text data-aos="fade-down" fontSize={["26px", "32px"]} fontWeight="700" pb="3" borderBottom="1px solid rgb(150,150,150)">What we offer</Text>
						<Flex mb="4" mt="5" display={["flex", "none"]} w={["100%", "50%"]} backgroundImage={about} backgroundRepeat="no-repeat" backgroundPosition="center center" backgroundSize="cover" borderRadius="8px" h={["80px", "700px"]}></Flex>
						<Text data-aos="fade-up" color="slategray" mt="4" mb="8">Our unique service measures the effect of international marketing inputs on sales or market share, providing you with the insights you need to optimize your marketing spend.</Text>
						{
							["Assurance of quality service for both buyers and sellers", "Local agents in every country for complete transparency", "Only 5% commission on total sales", "Swift transactions to any country", "Direct communication with clients", "Acceptance of cryptocurrency payment"].map((item, index) => (
								<Flex mb="6" key={index} align="center">
									<Flex w="36px" h="35px" bg="rgb(220,220,220)" borderRadius="100%" justify="center" align="center" fontSize={["17px", "20px"]} fontWeight="700">0{index + 1}</Flex>
									<Flex flex="1" ml="5" data-aos="fade-up" fontSize={["13px", "15px"]}>{item}</Flex>
								</Flex>
							))
						}
					</Flex>
				</Flex>



				<Flex w="100%" bg="rgba(224, 150, 13, 0.08)" direction="column" px={["5", "8%"]} py={["60px", "80px"]} id="aCrea">
					<Text data-aos="fade-down" fontSize={["26px", "32px"]} fontWeight="700" pb="3" borderBottom="1px solid rgb(150,150,150)" textAlign="center">Our Features</Text>

					
					<Flex w="100%" overflowX="scroll" className="noscbar" id="scEleTwos" borderRadius="8px">
						<Flex flexWrap="nowrap" minW={["896", "2020px"]} align="center" mt={["8", "8"]} overflowX="scroll" className="noscbar">
							{
								[{title: "Direct Communication", txt: "Our platform allows for direct communication between buyers and sellers, ensuring a seamless transaction process.", dp: cover2}, {title: "Global Operations", txt: "We operate in any country across the world, providing a truly global marketplace for our clients.", dp: cover4}, {title: "Cryptocurrency Acceptance", txt: "Our platform is up-to-date with modern technology and accepts payment in cryptocurrency, providing our clients with a convenient payment option.", dp: cover3},].map((item, index) => (
									<Flex borderRadius="8px" key={index} backgroundImage={item.dp} backgroundRepeat="no-repeat" w={["290px", "600px"]} h={["350px", "400px"]} ml={[index === 0 ? "0px" : "13px", index === 0 ? "120px" : "50px"]} backgroundPosition="center left" backgroundSize="cover">
										<Flex borderRadius="8px" w="100%" h="100%" bg="rgba(0,0,0,0.6)" direction="column" color="#fff" px={["8", "12"]} justify="center">
											<Text fontWeight="700" fontSize={["20px", "40px"]}>{item.title}</Text>
											<Text fontSize={["15px", "20px"]}>{item.txt}</Text>
										</Flex>
									</Flex>
								))
							}
						</Flex>
					</Flex>
				</Flex>



				<Flex w="100%" bg="#fff" py={["60px", "80px"]} px={["5", "8%"]} direction={["column", "row"]}>
					<Flex w={["100%", "50%" ]}direction="column">
						<Text data-aos="fade-down" mt={["0", "12"]} fontSize={["26px", "32px"]} fontWeight="700" pb="3" borderBottom="1px solid rgb(150,150,150)">Who Should You Use This Product? </Text>
						<Text color="slategray" mt="8" data-aos="fade-up">Middlesales International (MSI) is ideal for merchants and buyers seeking a secure and trustworthy platform for their transactions. Additionally, our platform is perfect for those seeking a global marketplace and those who prefer to use cryptocurrency for their transactions.</Text>
					</Flex>
					<Flex w={["100%", "40%"]} mt={["8", "0"]} ml={["0", "10%"]} direction="column">
						{
							[{title: "Get Started", sub: "Join our platform today and start connecting with buyers and sellers across the world"}, {title: "Contact Us", sub: "Reach out to our team for any queries you may have about our platform and how it can benefit you"}, {title: "Request a Quote", sub: "Visit our website to learn more about our platform and the services we provide"}].map((item, index) => (
								<>
								<Text key={index} fontSize={["17px", "20px"]} fontWeight="500">{item.title}</Text>
								<Text mb="4">{item.sub}</Text>
								{index > 0 &&<Flex mb="12" data-aos="fade-up" align="center"><Text mr="3" color="#e0960d">➜</Text><Flex bg="#e0960d" color="#fff" cursor="pointer" borderRadius="30px" p="10px 40px" fontWeight="700" onClick={() => {
									window.tidioChatApi.open()
								}}>Proceed</Flex></Flex>}
								</>
							))
						}
					</Flex>
				</Flex>


				<Flex py={["60px", "80px"]} bg="rgba(250,250,252)" px={["5", "8%"]} direction="column">
					<Text data-aos="fade-down" fontSize={["24px", "32px"]} fontWeight="700" pb="4" borderBottom="1px solid rgb(150,150,150)" textAlign="center">Frequently Asked Questions (FAQ's)</Text>
					<Flex mt={["0", "6"]} direction="column" w="100%" px={["5", "15%"]} data-aos="fade-up">
						<Accordion transition transitionTimeout={200}>
							<AccordionItem header="How does Middlesales International provide assurance of quality service for buyers and sellers?">
							We employ local agents in each country to oversee transactions and ensure complete transparency, providing assurance of quality service for both parties.
							</AccordionItem>

							<AccordionItem header="Can I use cryptocurrency to pay for transactions on Middlesales International?">
							Yes, we accept payment in cryptocurrency, providing our clients with a convenient payment option.
							</AccordionItem>

							<AccordionItem header="How much commission does Middlesales International charge on total sales?">
							We charge only 5% commission on total sales, making it an affordable option for buyers and sellers.
							</AccordionItem>
						</Accordion>
					</Flex>
				</Flex>
				<Flex w="100%" py="60px" bg="#000" px={["5", "8%"]} fontSize={["13px", "15px"]} justify="space-between" color="#fff">
					<Text>&copy; 2023 Middlesales International (MSI)</Text>
					<Flex direction={["column", "row"]}>
						<Text mr={["0", "12"]} onClick={() => window.oepn("mailto: info@middlesales.com", "_SELF")} cursor="pointer">info@middlesales.com</Text>
						<Text mt={["4", "0"]}>All rights reserved.</Text>
					</Flex>
				</Flex>
			</Flex>
		</ChakraProvider>
	)
}

export default App;
